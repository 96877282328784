<template>
  <b-form-group v-bind="commonFormGroupProps">
    <MultiSelectForm
        :defaultOptions="item.data"
        :trackBy="item.keyValue"
        :label="item.keyText"
        :searchUrl="item.url"
        :value="selectedValues"
        :class-input="classInput"
        @input="handleInput"
        :placeholderText="item.placeholder || 'Select items'"
        :labelSelectedText="item.selectedText || 'items selected'"
    >
      <template v-slot:multi-select__selected="{ option }">
        <slot :name="`multi-select__selected(${filterKey})`" :option="option" />
      </template>
      <template v-slot:multi-select__text="{ option }">
        <slot :name="`multi-select__text(${filterKey})`" :option="option">
          <div v-if="filterKey === 'countries'" class="d-flex ml-50">
            <CountryElement v-if="option.id || option.default_currency" :code="option.code" :name="option.name" />
          </div>

          <div v-else-if="filterKey === 'suppliers'" class="d-flex ml-50">
            <SupplierElement v-if="option.id" :id="option.id" :name="option.name" without-linked />
            <span v-else>{{ option.name }}</span>
          </div>

          <div v-else-if="filterKey === 'apps'" class="d-flex ml-50">
            <span v-if="option.id">{{ option[item.keyText] }} ({{ option[item.keyValue] || option.id }})</span>
            <span v-else>{{ option[item.keyText] }}</span>
          </div>

          <div v-else-if="filterKey === 'supplier_buyers'" class="d-flex ml-50">
            <SupplierBuyerElement v-if="option" :supplierBuyer="option" />
            <span v-else>{{ option.name }}</span>
          </div>

          <div v-else class="ml-50">{{ option[item.keyText] }}</div>
        </slot>
      </template>
      <template v-slot:multi-select__one_selection="{ item }">
        <slot :name="`multi-select__one_selection(${filterKey})`" :item="item" />
      </template>
    </MultiSelectForm>
  </b-form-group>
</template>

<script>
import MultiSelectForm from '@/main/custom-components/MultiSelectForm.vue'
import { formMixin } from '@/mixins/formMixin'
import SupplierBuyerElement from '@/views/elements/SupplierBuyerElement.vue'

export default {
  components: {SupplierBuyerElement, MultiSelectForm },
  mixins: [formMixin],
  props: ['item', 'value', 'filterKey', 'classInput'],
  computed: {
    commonFormGroupProps () {
      return {
        ...this.getCommonFormGroupProps()
      }
    },
    selectedValues () {
      return this.item.data.filter(
        option => this.value.some(val => String(val) === String(option[this.item.keyValue]))
      )
    }
  },
  methods: {
    handleInput (values) {
      // Combine existing data with the new values
      const combined = [...this.item.data, ...values]

      // Create a unique map where each key (converted to a string) maps to its item
      const uniqueMap = {}
      combined.forEach(item => {
        uniqueMap[String(item[this.item.keyValue])] = item
      })

      // Extract the unique items as an array
      const uniqueData = Object.values(uniqueMap)

      // Update the original data array in place using Object.assign
      Object.assign(this.item.data, uniqueData)

      // Emit only the keys from the new values
      const keys = values.map(v => v[this.item.keyValue])
      this.$emit('input', keys)
      this.$nextTick(() => {
        this.$emit('change', keys)
      })
    }
  }
}
</script>
